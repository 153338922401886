
import { defineComponent } from "vue";
import { i18n } from "@/i18n";

export default defineComponent({
  name: "ComplementaryFeeding",
  setup() {
    const { t } = i18n.global;

    const openTable = () => {
      console.log("openTable()");
      // this.showTable = true;
      // this.setTable();
    };

    return {
      t,
      openTable
    };
  }
});
